/**************** React Slick *****************/

.galleries-section .slick-active {
  padding-top: 20px;
  padding-bottom: 20px;
}

.galleries-section .slick-slide:not(.slick-active) {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 1024px) {
  .galleries-section .slick-active {
    padding-top: 34px;
    padding-bottom: 20px;
  }

  .galleries-section .slick-slide:not(.slick-active) {
    padding-top: 34px;
    padding-bottom: 20px;
  }
}

.galleries-section .child {
  width: 100%;
}

.galleries-section .slide:not(.slick-active) {
  cursor: pointer;
}

.galleries-section .pagination {
  text-align: center;
  color: white;
}
