.volume-range {
  -webkit-appearance: none;
  accent-color: #e8e8e8;
  height: 5px;
  cursor: pointer;
  transition: all 0.8s ease-in-out;
}

.volume-range-bar {
  cursor: pointer;
  transition: all 0.8s ease-in-out;
}

/* input[type='range'] {
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 20px;
  border-radius: 50%;
  background: goldenrod;
  margin-top: -4px;
  display: none;
} */

/* input[type='range']::-webkit-slider-thumb {
  width: 0px;
  -webkit-appearance: none;
  height: 0px;
} */

.setting-main-menu:hover {
  opacity: 1;
  background-color: #27272790;
}

.vjs-settings-menu-mobile {
  display: none;
}
