.content-image {
  margin-left: 29vw;
}
.trailer-player-div{
  height: calc(70vw / 1.7777);
}

.content-image-div > img {
  height: calc(70vw / 1.7777);
  width: 100%;
}
@media screen and (max-width: 1280px) {
  .content-image {
    margin-left: 0vw;
  }
  .content-image::after{
    width: 0;
  }
  .trailer-player-div{
    height: calc(100vw / 1.7777) !important;
  }
  .content-image-div > img {
    height: calc(100vw / 1.7777);
    width: 100%;
  }
  
}

.content-loader {
  height: calc(70vw / 1.7777);
}

.full-img-content {
  height: calc(100vw / 1.7777);
}

.multi-card img {
  width: 100%;
}
