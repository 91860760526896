/**************** React Slick *****************/

.hero-section .slick-active {
  padding: 0px 0;
}
.hero-section .slick-center {
  transform: scale(1);
}
.hero-section .slick-slide:not(.slick-active) {
  margin: 0px 0 0px 0;
  transform: scale(1);
  /* transition: 0.5s; */
}
.hero-section .child {
  width: 100%;
}
.hero-section .slide:not(.slick-active) {
  cursor: pointer;
}
.hero-section .pagination {
  text-align: center;
  color: white;
}
