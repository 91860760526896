.slider-ul {
  display: flex;
  align-items: center;
  list-style: none;
  max-width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.slider-ul::-webkit-scrollbar {
  background: transparent; /* make scrollbar transparent */
  -webkit-appearance: none;
  width: 0;
  height: 0;
}

.slider-li {
  color: #fff;
  margin: 0 18px;
  white-space: nowrap;
}

.slider-li-selected {
  color: #09e9e9;
  margin: 0 18px;
  white-space: nowrap;
  font-weight: 500;
}
