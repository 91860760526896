.content-image-div > img {
  height: calc(70vw / 1.7777);
  width: 100%;
}

.content-loader {
  height: calc(70vw / 1.7777);
}

.full-img-content {
  height: calc(100vw / 1.7777);
}

.new-content-image {
  /* margin-left: 29.375vw; */
}

.new-content-image-div {
  width: 100%;
  aspect-ratio: 1.7777;
  /* width: 100%; */
}

@media (min-width: 1280px) {
  .content-image {
    margin-left: 29vw;
  }
  .new-content-image {
    width: 62%;
  }
  .content-detail-info-box {
    width: 35%;
  }

  .new-content-loader {
    height: calc(59vw / 1.7777);
  }
}

@media (min-width: 1536px) {
  .new-content-image {
    width: 66.881%;
  }
  .content-detail-info-box {
    width: 31%;
  }
}

.icon-hover:hover {
  color: var(--hover-color) !important;
}
