.App {
  background-color: #1b242f;
  /* min-height: 100vh; */
}

/*.slide {*/
/*  opacity: 0;*/
/*  transition-duration: 1s;*/
/*}*/

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  right: 0;
  transform: scale(1.02);
  /* transform: scaleX(1.03); */
}

.image-div > img {
  height: 68vh;
}

.image {
  width: 100%;
  border-radius: 0.62rem;
}

.right-arrow {
  position: absolute;
  top: 45%;
  right: 5%;
  /*right: 4.5rem;*/
  transform: translateY(-50%);
  border: none;
  font-size: 4.1rem;
  color: #e3e2e2;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 45%;
  left: 5%;
  transform: translateY(-50%);
  border: none;
  /* right: 52rem; */
  /* margin-right: 20rem; */
  font-size: 4.1rem;
  color: #e3e2e2;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.container {
  /* position: relative; */
  /* max-width: 100%; */
  /* min-height: 60vh; */
  /* height: 43rem; */
  /* margin: auto; */
}

.underline-2px {
  text-decoration-thickness: 2px;
}

.hidden-scrollbar-class {
  overflow-x: hidden;
  overflow-y: auto;
  /* &:hover {
    overflow-y: auto;
  } */
}

.hidden-scrollbar-class::-webkit-scrollbar {
  width: 0rem;
}

.hidden-scrollbar-class::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(236, 236, 236, 0.3);
  border-radius: 10px;
}

.hidden-scrollbar-class::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(247, 246, 246, 0.3);
}
.scrollbar-class {
  overflow-x: hidden;
  overflow-y: auto;
  /* &:hover {
    overflow-y: auto;
  } */
}

.scrollbar-class::-webkit-scrollbar {
  width: 0.5rem;
}

.scrollbar-class::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(236, 236, 236, 0.3);
  border-radius: 10px;
}

.scrollbar-class::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(247, 246, 246, 0.3);
}

.custom-scrollbar-vertical::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: #f5f5f5;
}

.custom-scrollbar-vertical::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f5f5;
}

.custom-scrollbar-vertical::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4e4e4e;
}

.custom-form-field {
  overflow: hidden;
  max-height: 42vh;
}

.custom-form-field:hover {
  overflow-y: scroll;
}

.right-Swimlane-arrow {
  position: absolute;
  top: 7rem;
  opacity: 0.8;
  /* right: 5%; */
  right: 3.5rem;
  transform: translateY(-50%);
  border: none;
  font-size: 3rem;
  color: #e3e2e2;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.right-Swimlane-arrow:hover {
  opacity: 1;
}

.next-Swimlane-arrow {
  /* transform: translateY(-50%); */
  position: absolute;
  opacity: 0.8;
  font-size: 3rem;
  color: #e3e2e2;
  top: 25px;
  right: 0;
  width: 5.52%;

  z-index: 10;
  cursor: pointer;
  height: 100%;
  user-select: none;
  transition: opacity 0.5s;
}

.next-Swimlane-arrow:hover {
  opacity: 1;
  transition: opacity 0.5s;
  background: #00000090;
}

.portrait-owl-next-arrow {
  /* transform: translateY(-50%); */
  position: absolute;
  opacity: 0.8;
  font-size: 3rem;
  color: #e3e2e2;
  top: 15px;
  right: 0;
  width: 5.52%;

  z-index: 10;
  cursor: pointer;
  height: 100%;
  user-select: none;
  transition: opacity 0.5s;
}

.portrait-owl-next-arrow:hover {
  opacity: 1;
  transition: opacity 0.5s;
  background: #00000090;
}

.prev-Swimlane-arrow {
  /* transform: translateY(-50%); */
  position: absolute;
  opacity: 0.5;
  font-size: 3rem;
  color: #e3e2e2;
  width: 5.52%;
  top: 25px;

  z-index: 10;
  cursor: pointer;
  height: 100%;
  user-select: none;
  transition: opacity 0.5s;
}

.prev-Swimlane-arrow:hover {
  opacity: 1;
  transition: opacity 0.5s;
  background: #00000090;
}

.portrait-owl-prev-arrow {
  /* transform: translateY(-50%); */
  position: absolute;
  opacity: 0.5;
  font-size: 3rem;
  color: #e3e2e2;
  width: 5.52%;
  top: 15px;
  z-index: 10;
  cursor: pointer;
  height: 100%;
  user-select: none;
  transition: opacity 0.5s;
}

.portrait-owl-prev-arrow:hover {
  opacity: 1;
  transition: opacity 0.5s;
  background: #00000090;
}

.left-Swimlane-arrow {
  position: absolute;
  top: 7rem;
  left: 3.5rem;
  transform: translateY(-50%);
  opacity: 0.8;
  /* border: 1px solid black; */
  /* right: 52rem; */
  /* margin-right: 20rem; */
  font-size: 3rem;
  color: #e3e2e2;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-Swimlane-arrow:hover {
  opacity: 1;
}

.swimlane-container {
  position: relative;
  max-width: 100%;
  /*height: 8rem;*/
  z-index: 10;
}
.swimlane-container-hover {
  position: relative;
  max-width: 100%;
  height: 14rem;
  z-index: 40;
}

.rotate-profile-arrow {
  transform: rotate(180deg);
  transition: transform 400ms;
}
.reset-profile-arrow {
  transform: rotate(0deg);
  transition: transform 400ms;
}

@media only screen and (min-width: 1025px) {
  .swimlane-container {
    position: relative;
    max-width: 100%;
    /*height: 14rem;*/
    z-index: 10;
  }
}

/* .hide-btn { */
/* position: relative; */
/* border: none; */
/* border: 1px solid black; */
/* background-color: inherit; */
/* justify-content: space-between; */
/* display: flex; */
/* } */

.user-dropdown-menu {
  opacity: 0.8;
}
.user-dropdown-menu:hover {
  opacity: 1;
  transition-duration: 500ms;
}

.error-message > p {
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
  color: red;
}

/* .slider { */
/* position: relative; */
/* margin-top: 1.5em; */
/* height: ; */
/* display: flex; */
/* justify-content: center; */
/* align-items: center; */
/* } */

/* .image {
  width: 80rem;
  height: 50vh;
  border-radius: 10px;
} */

/* .right-arrow {
  position: absolute;
  top: 45%;
  right: 90px;
  font-size: 4.1rem;
  color: #e3e2e2;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 45%;
  left: 90px;
  font-size: 4.1rem;
  color: #e3e2e2;
  z-index: 10;
  cursor: pointer;
  user-select: none;
} */

/* .image-swimlane {
  width: 100%;
} */

/* .image-swimlane-div:focus,
.image-swimlane-div:hover {
  transform: scale(1.1);
  z-index: 1;
  border: 1.5px solid #e3e2e2;
  background-color: #1b242f;
}

.image-swimlane-div:hover .hidden-swimlane-div {
  display: block;
}

.image-swimlane-div > img {
  height: 10.1rem;
}
.image-swimlane-div {
  position: relative;
  transition: transform 500ms;
  height: 15.5rem;
  z-index: 10;
  margin-top: 1rem;
  cursor: pointer;
} */

/* .hidden-swimlane-div {
  display: none;
  transition: transform 500ms;
  font-family: Arial;
  margin: 1rem;
  overflow-wrap: break-word;
  border: 1px solid red;
} */

@import url('https://fonts.googleapis.com/css?family=Droid+Sans+Mono');
* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

#app {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #fbab7e;
  background-image: linear-gradient(62deg, #fbab7e 0%, #f7ce68 100%);
}

header {
  display: flex;
  position: relative;
}
header h1 {
  font-family: 'Droid Sans Mono', monospace;
  font-weight: lighter;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: white;
}

@media only screen and (min-device-width: 480px) {
  .flipClock {
    display: flex;
    justify-content: space-between;
    width: 500px;
  }
}

.flipUnitContainer {
  display: block;
  position: relative;
  width: 140px;
  height: 120px;
  perspective-origin: 50% 50%;
  perspective: 300px;
  background-color: #1b242ff7;
  border-radius: 3px;
  margin-right: 5px;
  box-shadow: 0px 10px 10px -10px grey;
}

.upperCard,
.lowerCard {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 50%;
  overflow: hidden;
  border: 1px solid whitesmoke;
}
.upperCard span,
.lowerCard span {
  font-size: 5em;
  font-family: 'Droid Sans Mono', monospace;
  font-weight: lighter;
  color: #ffffff;
}

.upperCard {
  align-items: flex-end;
  border-bottom: 0.5px solid whitesmoke;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.upperCard span {
  transform: translateY(50%);
}

.lowerCard {
  align-items: flex-start;
  border-top: 0.5px solid whitesmoke;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.lowerCard span {
  transform: translateY(-50%);
}

.flipCard {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.flipCard span {
  font-family: 'Droid Sans Mono', monospace;
  font-size: 5em;
  font-weight: lighter;
  color: #ffffff;
}
.flipCard.unfold {
  top: 50%;
  align-items: flex-start;
  transform-origin: 50% 0%;
  transform: rotateX(180deg);
  background-color: #1b242ff7;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 0.5px solid whitesmoke;
  border-top: 0.5px solid whitesmoke;
}
.flipCard.unfold span {
  transform: translateY(-50%);
}
.flipCard.fold {
  top: 0%;
  align-items: flex-end;
  transform-origin: 50% 100%;
  transform: rotateX(0deg);
  background-color: #1b242ff7;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: 0.5px solid whitesmoke;
  border-bottom: 0.5px solid whitesmoke;
}
.flipCard.fold span {
  transform: translateY(50%);
}

.fold {
  -webkit-animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
  animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
  transform-style: preserve-3d;
}

.unfold {
  -webkit-animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
  animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
  transform-style: preserve-3d;
}

@-webkit-keyframes fold {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-180deg);
  }
}

@keyframes fold {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-180deg);
  }
}
@-webkit-keyframes unfold {
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
@keyframes unfold {
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

@media only screen and (max-device-width: 1159px) {
  .image-div > img {
    height: 8.5rem;
  }

  .flipClock {
    display: flex;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }

  .flipUnitContainer {
    display: block;
    position: relative;
    width: 120px;
    height: 70px;
    perspective-origin: 50% 50%;
    perspective: 100px;
    background-color: #1b242ff7;
    border-radius: 3px;
    margin-right: 5px;
    box-shadow: 0px 10px 10px -10px grey;
  }

  .upperCard span,
  .lowerCard span {
    font-size: 2em;
    font-family: 'Droid Sans Mono', monospace;
    font-weight: lighter;
    color: #ffffff;
  }

  .flipCard span {
    font-family: 'Droid Sans Mono', monospace;
    font-size: 2em;
    font-weight: lighter;
    color: #ffffff;
  }
}

.overlay {
  transition: 0.5s ease;
  opacity: 1;
  position: absolute;
  /* top: 10%; */
  /* transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%); */
  /* text-align: center; */
}

@media (min-width: 1025px) {
  .overlay {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
  }
  .subscription-title-width {
    width: calc(100% - 115px);
  }
}

.player-container:hover .overlay {
  opacity: 0.8;
}

.line-clamp-episode-card {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  /*max-width: 200px;*/
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-1 {
  display: -webkit-box;
  /*max-width: 200px;*/
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-4 {
  display: -webkit-box;
  /*max-width: 500px;*/
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-5 {
  display: -webkit-box;
  /*max-width: 500px;*/
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.atg-header-gradient{
  background: -webkit-linear-gradient(left ,#d20c83, #0dedf5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.atg-register-gradient{
  background-image: linear-gradient(to right, #d20c83, #0dedf5);
}
.venue-detail-image {
  margin-left: 40vw;
}

.venue-detail-image-div > img {
  height: calc(59vw / 1.7777);
  width: 100%;
}

.atg-header-gradient {
  background: -webkit-linear-gradient(left, #d20c83, #0dedf5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.static-img-scale:hover img {
  transition-duration: 0.5s;
  transform: scale(1.3);
  /* transform: scaleX(1.03); */
}

.static-img-scale img {
  transition-duration: 0.5s;
  transform: scale(1);
  /* transform: scaleX(1.03); */
}

.clamp-text-15-char {
  width: 15ch; /* Sets the width to 30 characters */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Shows ellipsis (...) to indicate truncated text */
  white-space: nowrap; /* Prevents text from wrapping to the next line */
}

.clamp-text-30-char {
  width: 30ch; /* Sets the width to 30 characters */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Shows ellipsis (...) to indicate truncated text */
  white-space: nowrap; /* Prevents text from wrapping to the next line */
}

.watermark-top-center {
  top: calc(50% - 40px);
}

.watermark-center {
  left: calc(50% - (60px * 1.777) / 1.5);
}

/* Chrome, Safari, Edge, Opera */
input.otp-input::-webkit-outer-spin-button,
input.otp-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input.otp-input[type='number'] {
  -moz-appearance: textfield;
}
