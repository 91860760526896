.rhap_time {
  font-size: 12px;
  color: #fff;
}

.rhap_progress-bar {
  height: 3px;
}

.rhap_progress-bar-show-download {
  background-color: #aaaaaa;
}

.rhap_progress-filled {
  background-color: #09e9e9;
}

.rhap_progress-indicator {
  background-color: #09e9e9;
  width: 10px;
  height: 10px;
  margin-top: 4px;
}

.rhap_button-clear {
  color: #fff;
}

.rhap_volume-bar {
  height: 3px;
}
.rhap_volume-indicator {
  background-color: #fff;
}
