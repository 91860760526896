/* * {
  box-sizing: border-box;
} */

.left-Swimlane-arrow1 {
  position: absolute;
  /* top: 50%; */
  left: 90px;
  font-size: 3.1rem;
  color: #e3e2e2;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.right-Swimlane-arrow1 {
  position: absolute;
  /* top: 45%; */
  right: 20px;
  font-size: 3.1rem;
  color: #e3e2e2;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-Swimlane-arrow {
  position: absolute;
  top: 50%;
  left: 3.5rem;
  transform: translateY(-50%);
  opacity: 0.8;
  /* border: 1px solid black; */
  /* right: 52rem; */
  /* margin-right: 20rem; */
  font-size: 3rem;
  color: #e3e2e2;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-Swimlane-arrow:hover {
  opacity: 1;
}

.right-Swimlane-arrow {
  position: absolute;
  top: 50%;
  opacity: 0.8;
  /* right: 5%; */
  right: 3.5rem;
  transform: translateY(-50%);
  border: none;
  font-size: 3rem;
  color: #e3e2e2;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.right-Swimlane-arrow:hover {
  opacity: 1;
}

/* .image-swimlane { */
/* width: 100%; */
/* margin-left: 3rem; */
/* height: auto; */
/* border-radius: 10px; */
/* } */


/* slider style only for artists */
.slide-for-artists .slide___3-Nqo{
  height: 480px !important;
  aspect-ratio: 7/10 !important;
}

@media screen and (max-width: 1536px) {
  .slide-for-artists .slide___3-Nqo {
    height: 360px !important;
    aspect-ratio: 7/10 !important;
  }
}
@media screen and (max-width: 1280px) {
  .slide-for-artists .slide___3-Nqo {
    height: 500px !important;
    aspect-ratio: 7/10 !important;
  }
}
@media screen and (max-width: 1024px) {
  .slide-for-artists .slide___3-Nqo {
    height: 40vw !important;
    aspect-ratio: 7/10 !important;
  }
}
@media screen and (max-width: 640px) {
  .slide-for-artists .slide___3-Nqo {
    height: 55vw !important;
    aspect-ratio: 7/10 !important;
  }
}

.slide-for-venues .slide___3-Nqo {
  height: 250px !important;
  aspect-ratio: 16/9 !important;
}

@media screen and (max-width: 1024px) {
  .slide-for-venues .slide___3-Nqo {
    height: 25vw !important;
    aspect-ratio: 7/10 !important;
  }
}
@media screen and (max-width: 640px) {
  .slide-for-venues .slide___3-Nqo {
    height: 25vw !important;
    aspect-ratio: 7/10 !important;
  }
}