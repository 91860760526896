/**************** React Slick *****************/
.trainers-section .slick-active {
  padding: 20px 0;
}

.trainers-section .slick-center {
  transform: scale(1.08);
}

.trainers-section .slick-slide:not(.slick-active) {
  margin: 50px 0 0px 0;
  transform: scale(0.78);
}

.trainers-section .child {
  width: 100%;
}

.trainers-section .slide:not(.slick-active) {
  /* cursor: pointer; */
}

.trainers-section .pagination {
  text-align: center;
  color: white;
}

.inner-div {
  display: flex !important ;
}

.trainers-section .slick-dots {
  border-radius: 0px !important;
  padding: 0px !important;
  bottom: -45px !important;
}

@media (min-width: 768px) {
  .trainers-section .slick-dots {
    border-radius: 0px !important;
    padding: 0px !important;
    bottom: -5.5vw !important;
  }
}
@media (min-width: 1280px) {
  .trainers-section .slick-dots {
    border-radius: 0px !important;
    padding: 0px !important;
    bottom: -55px !important;
  }
}
@media (min-width: 1536px) {
  .trainers-section .slick-dots {
    border-radius: 0px !important;
    padding: 0px !important;
    bottom: -3vw !important;
  }
}

/* .react-3d-carousel {
  width: 100% !important;
  height: 100% !important;
}
.react-3d-carousel .slider-container {
  position: relative !important;
  width: 100% !important;
  margin: 0 !important;
}
.react-3d-carousel .slider-container .slider-content {
  position: relative !important;
  left: 0% !important;
  top: 0% !important;
  width: 100% !important;
  height: 100% !important;
  transform: translateX(-22%) !important;
}
.react-3d-carousel .slider-container .slider-content .slider-single.active .slider-single-content {
  opacity: 1;
  transform: translateX(0%) scale(1);
  width: calc(100vw * 0.43);
}

.react-3d-carousel
  .slider-container
  .slider-content
  .slider-single.proactive
  .slider-single-content {
  opacity: 0.9;
  transform: translateX(160%) scaleX(1) scaleY(0.6);
  width: 25%;
  margin-top: 2%;
  font-size: 10px;
}

.react-3d-carousel
  .slider-container
  .slider-content
  .slider-single.preactive
  .slider-single-content {
  opacity: 0.9;
  transform: translateX(-80%) scaleX(1) scaleY(0.6);
  width: 25%;
  margin-top: 2%;
}

.react-3d-carousel .slider-container .slider-right div {
  visibility: hidden;
}
.react-3d-carousel .slider-container .slider-left div {
  visibility: hidden;
}

.fitness-trainer-div {
  width: calc(100vw * 0.43);
  height: calc((100vw * 0.43) / 1.9);
} */
