
  .fitness-plan-line-clamp-sm {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .fitness-plan-line-clamp-lg {
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .fitness-trainer-line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .default-banner-player-icon{
    top: 0;
    right: 0;
  }

  .decomposed-banner-player-icon{
    top: 33%;
    right: 0;
  }

  .header-menu-underline:hover { 
    text-decoration-line: underline;
  }

  @media (min-width: 768px) {

    
    .fitness-plan-line-clamp-sm {
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .fitness-plan-line-clamp-lg {
      display: -webkit-box;
      -webkit-line-clamp: 16;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .fitness-trainer-line-clamp {
      display: -webkit-box;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .tw-line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .trending-news-line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @media screen and (min-width:768px) {
      -webkit-line-clamp: 5;
    }
    @media screen and (min-width:640px) {
      -webkit-line-clamp: 3;
    }
  }

  @media (min-width: 1280px) {

    .default-banner-player-icon{
      top: 25%;
      right: 3.55%;
    }

    .decomposed-banner-player-icon{
      top: 27%;
      right: 6.875%;
    }
  }