.custom-page h1 {
  font-size: 2em;
  font-weight: bolder;
}
.custom-page h2 {
  font-size: 1.5em;
  font-weight: bolder;
}
.custom-page h3 {
  font-size: 1.17em;
  font-weight: bolder;
}
.custom-page h4 {
  font-size: 1em;
  font-weight: bolder;
}
.custom-page h5 {
  font-size: 0.83em;
  font-weight: bolder;
}
.custom-page h6 {
  font-size: 0.67em;
  font-weight: bolder;
}
.custom-page p {
  margin-bottom: 0 !important;
}
