.artist-image {
    max-width: 100%;
    /* width: 100%; */
    height: 42rem;
    /* margin: auto; */
  }
  

  .artist-image-div > img {
    height: 50rem;
    width: 100%;
  }
  