
 /* custom video js styles */
 .vjs-playback-rate .vjs-playback-rate-value {
    line-height: 54px !important;
  }
  .vjs-playback-rate .vjs-menu {
    width: 6em !important;
  }
  .vjs-playback-rate .vjs-menu .vjs-menu-content {
    max-height: 20em;
  }
   /* custom video js styles */